import React from 'react';

function SizeAndFitDetails({ sizeAndFitText }) {
  const renderParagraphs = () => {
    // Split the text into paragraphs using line breaks
    const paragraphs = sizeAndFitText.split('\n');

    return paragraphs.map((paragraph, index) => (
      <div key={index} className="size-and-fit-paragraph">
        {/* Parse bold text and apply styles */}
        {paragraph.split('**').map((text, i) =>
          i % 2 === 0 ? (
            // Normal text
            <span key={i}>{text}</span>
          ) : (
            // Bold text
            <strong key={i}>{text}</strong>
          )
        )}
      </div>
    ));
  };

  return (
    <div className="size-and-fit-details">
      {renderParagraphs()}
    </div>
  );
}

export default SizeAndFitDetails;
