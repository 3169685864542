import React, { useEffect, useState } from 'react';
import './Legal.css'; // Import the common CSS file

const CookiePolicy = () => {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    const fetchHtmlContent = async () => {
      try {
        const response = await fetch('/MyMallVibesCookiePolicy.html');
        const content = await response.text();
        setHtmlContent(content);
      } catch (error) {
        console.error('Error fetching HTML content:', error);
      }
    };

    fetchHtmlContent();
  }, []);

  return <div className="legal-page" dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};

export default CookiePolicy;
