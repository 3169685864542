import React from 'react';
import { FaInstagram, FaTiktok, FaPinterest } from 'react-icons/fa';
import styles from './Footer.module.css';

const logo = "/logo192.png";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.upperFooter}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="Runway Rewards" width="50" height="50" />
          <p className={styles.copyright}>
            Runway Rewards Shop
          </p>
        </div>
        <ul className={styles.linkContainer}>
          <li><a href="/terms-of-use">User Agreement</a></li>
          <li><a href="/privacy-policy">Privacy Policy</a></li>
          <li><a href="/cookie-policy">Cookie Policy</a></li>
        </ul>
      </div>

     
    </footer>
  );
};

export default Footer;
