import React, { useEffect, useState } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { useAuth } from '../AuthContext';
import Retool from 'react-retool';
import SizeAndFitDetails from './SizeAndFitDetails';
import SustainabilityDetails from './SustainabilityDetails';
import './ProductPopup.css';
import { InstantSearch, Configure, connectSearchBox } from 'react-instantsearch-dom';
import SearchBar from './SearchBarAlgolia';
import aa from 'search-insights';
import {Helmet} from "react-helmet";

const client = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_SEARCH_KEY);

aa('init', {
  appId: process.env.REACT_APP_ALGOLIA_APP_ID,
  apiKey: process.env.REACT_APP_ALGOLIA_SEARCH_KEY
});

const CustomSearchBox = ({ currentRefinement, refine }) => {
  return (
    <SearchBar
      value={currentRefinement}
      onChange={(e) => refine(e.target.value)}
    />
  );
};

const ConnectedSearchBox = connectSearchBox(CustomSearchBox);

function ProductPage() {
  const { user } = useAuth();
  const [product, setProduct] = useState(null);
  const [selectedImage, setSelectedImage] = useState('');
  const [expandedSection, setExpandedSection] = useState(null);
  const [sizeAndFitText, setSizeAndFitText] = useState('');
  const [sustainabilityText, setSustainabilityText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const imageUrls = product ? [1, 2, 3, 4, 5, 6].map(idx => product[`image_url${idx}`]).filter(img => img) : [];

  
  useEffect(() => {
    const pathSegments = window.location.pathname.split('/');
    const id = pathSegments.pop();
    if (!id) return;

    const fetchData = async () => {
      try {
        const index = client.initIndex('RunwayRewardsDataLake2024V2');
        const searchResult = await index.search('', { filters: `_id:${id}` });

        if (searchResult.hits.length === 0) {
          console.debug('No search matches');
          return;
        }

        const productData = searchResult.hits[0];
        setProduct(productData);
        setSelectedImage(productData.image_url1 || '');
        const sizeAndFitResponse = await fetchSizingData(productData.name);
        const impactResponse = await fetchSustainabilityData(productData.name);
        setSizeAndFitText(sizeAndFitResponse);
        setSustainabilityText(impactResponse);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const formatPrice = (price) => {
    const priceNumber = typeof price === 'number' ? price : parseFloat(price);
    return `$${Math.floor(priceNumber)}`;
  };

  const handleNextClick = () => {
    console.log("Next clicked");
    setCurrentIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
  };
  
  const handlePrevClick = () => {
    console.log("Prev clicked");
    setCurrentIndex((prevIndex) => (prevIndex - 1 + imageUrls.length) % imageUrls.length);
  };

  const handleImageSectionClick = (e) => {
    const bounds = e.target.getBoundingClientRect();
    const x = e.clientX - bounds.left; // Click X position within the element
    const width = bounds.right - bounds.left;
  
    if(x < width / 2) {
      handlePrevClick(); // Clicked on the left side, assuming left arrow click
    } else {
      handleNextClick(); // Clicked on the right side, assuming right arrow click
    }
  };

  const changeImage = (direction) => {
    // Array of available image URLs from the product
    const imageUrls = [product.image_url1, product.image_url2, product.image_url3, product.image_url4, product.image_url5, product.image_url6].filter(Boolean);
    
    let newIndex = imageUrls.indexOf(selectedImage) + direction;
    // Wrap around navigation
    if (newIndex < 0) newIndex = imageUrls.length - 1;
    else if (newIndex >= imageUrls.length) newIndex = 0;
    
    setSelectedImage(imageUrls[newIndex]);
  };

  const getAffiliateLink = (url, network, userId, advertiserId) => { 
    console.log('Original URL:', url); 
    console.log('Network:', network); 
    console.log('UserID:', userId); 
    console.log('Advertiser ID:', advertiserId); 
          
    let updatedUrl = url; 
    switch (network) { 
        case 'AWIN': 
            updatedUrl = url.replace(/(awinmid=[^&]+&awinaffid=[^&]+)(.*)/, `$1${userId ? '&clickref=' + userId : ''}$2`); 
            break; 
        case 'CJ': 
            updatedUrl = url.replace(/(click-[^-]+-[^?]+)\?/, `$1${userId ? '?sid=' + userId : ''}&`); 
            break; 
        case 'RAK':
          let murl = new URLSearchParams(url.split('?')[1] || '').get('murl');
          if (!murl) {
              console.error('Unable to extract murl from the given url', url);
              break;
          }
          updatedUrl = `https://click.linksynergy.com/deeplink?id=ZXkZxh*1HDA&mid=${advertiserId}&murl=${encodeURIComponent(murl)}`;
          if (userId) {
              updatedUrl += `&u1=${userId}`;
          }
              break; 
        default: 
            // Leave the url as it is 
            break; 
    } 
    return updatedUrl; 
};

  

  const fetchSizingData = async (productName) => {
    try {
      const response = await fetch(`https://bard.runwayrewards.shop/sizing?user_input=${encodeURIComponent(productName)}`);
      const data = await response.json();
      return data.response || '';
    } catch (error) {
      console.error('Error fetching sizing data:', error);
      return '';
    }
  };

  const fetchSustainabilityData = async (productName) => {
    try {
      const response = await fetch(`https://bard.runwayrewards.shop/impact?user_input=${encodeURIComponent(productName)}`);
      const data = await response.json();
      return data.response || '';
    } catch (error) {
      console.error('Error fetching sustainability data:', error);
      return '';
    }
  };


  // Add helmet meta tags here, just before your main return statement.
  // Helmet tags will be rendered only when the 'product' state is available.
  let helmetTags = null;
  if (product) {
    const images = Array.from(Array(6).keys()) // creates an array [0,1,2,3,4,5]
    .map(i => product[`image_url${i + 1}`]) // creates an array with your images
    .filter(image => image); // removes any undefined or null images from the array
    helmetTags = (
        <Helmet>
          <title>{`${product.name} - Runway Rewards`}</title>
          <meta name='robots' content="index, nofollow" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta name='description' content={product.description} />
          <meta property='og:title' content={`${product.brand} ${product.name}`} />
          <meta property='og:description' content={product.description} />
          <meta property='pinterest:description' content={product.description} />
          <meta property='og:image' content={product.image_url1} />
          <meta property="og:type" content="website" />
          <meta property="og:offers" content={`Price: ${formatPrice(product.price_usd)}`} />
          <meta property="og:site_name" content="Runway Rewards Shop" />
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org/",
              "@type": "Product",
              "name": product.name,
              "image": images,
              "description": product.description,
              "additionalProperty": {
                "@type": "PropertyValue",
                "name": "Size",
                "value": product.size // Make sure to provide the actual size here. I'm assuming product.size exists.
              },
              "offers": {
                "@type": "Offer", 
                "price": product.price_usd,
                "priceCurrency": "USD" // Make sure you adapt if you plan on supporting different currencies
              },
              // Include your review and aggregateRating properties here, adjusted to fit your needs
              "category": product.category, // this could be "category": "Product Subcategory: subcategory, Product Category: category"
            })}
          </script>
        </Helmet>
      );
  }


  return (
    <div className="product-page">
        {helmetTags} {/* add your Helmet meta tags here */}
        <InstantSearch
            indexName="RunwayRewardsDataLake2024V2"
            searchClient={client}
            insights={{
                enabled: true,
            }}
        >
            <Configure hitsPerPage={500} />
            <ConnectedSearchBox />
            {/* ... rest JSX elements */}
        </InstantSearch>
        
        <div className="image-section">
  {imageUrls.length > 0 && (
    <>
      <button className="arrow left-arrow" onClick={handlePrevClick}>
        <svg viewBox="0 0 24 24" fill="currentColor" width="24px" height="24px"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path></svg>
      </button>
      <img src={imageUrls[currentIndex]} alt={product ? `${product.description}` : ''} />
      <button className="arrow right-arrow" onClick={handleNextClick}>
        <svg viewBox="0 0 24 24" fill="currentColor" width="24px" height="24px"><path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6z"></path></svg>
      </button>
    </>
  )}
</div>
        
        {product && (
            <div className="details-section">
                <div className="popup-header">
                    <span>{product.brand}</span>
                    <span>{product.category}</span>
                </div>
                <div className="product-name">{product.name}</div>
                <div className="medium-text">{formatPrice(product.price_usd)}</div>
                <div className="medium-text">{product.cashback}</div>
                <div className="expandable-section">
                    <div
                        className="section-header"
                        onClick={() =>
                            setExpandedSection(
                                expandedSection === 'Personalized Sizing Tool'
                                    ? null
                                    : 'Personalized Sizing Tool'
                            )
                        }
                    >
                        <div className="section-title">Personalized Sizing Tool</div>
                        <div className="expand-icon">
                            {expandedSection === 'Personalized Sizing Tool' ? '-' : '+'}
                        </div>
                    </div>
                    {expandedSection === 'Personalized Sizing Tool' && (
                        <div className="section-content">
                            <Retool
                                url="https://runwayrewards.retool.com/embedded/public/9f1bbb39-c1dc-4abb-a3f8-011d7b2be023"
                                data={{ productName: product.name, productSize: product.size }}
                            />
                        </div>
                    )}
                </div>
                
                {['Size and Fit', 'Description and Style Recommendations', 'Materials, Impact, and Sustainability Information'].map((section) => (
                    <div key={section} className="expandable-section">
                        <div
                            className="section-header"
                            onClick={() =>
                                setExpandedSection(section === expandedSection ? null : section)
                            }
                        >
                            <div className="section-title">{section}</div>
                            <div className="expand-icon">
                                {section === expandedSection ? '-' : '+'}
                            </div>
                        </div>
                        {section === expandedSection && (
                            <div className="section-content">
                                {section === 'Description and Style Recommendations'
                                    ? product.description
                                    : ''}
                                {section === 'Materials, Impact, and Sustainability Information' && (
                                    <div>
                                        <Retool
                                            url="https://runwayrewards.retool.com/embedded/public/ca57a994-d311-4aa7-bf72-9bbfe91a752f"
                                            data={{ productName: product.name, productBrand: product.brand, productDescription: product.description }}
                                        />
                                    </div>
                                )}
                                {section === 'Size and Fit' && (
                                    <SizeAndFitDetails sizeAndFitText={sizeAndFitText} />
                                )}
                            </div>
                        )}
                    </div>
                ))}
                
                {(() => {
                    // Calculate affiliateLink inside the map to ensure product is no longer null.
                    const affiliateLink = getAffiliateLink(
                        product.link,
                        product.network,
                        user?.uid,
                        product.advertiserId
                    );
                    return (
                        <a
                            href={affiliateLink}
                            className="secondary-button"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {user ? 'Buy with CashBack' : 'Buy Now from Store'}
                        </a>
                    );
                })()}
            </div>
        )}
    </div>
);
}
export default ProductPage;