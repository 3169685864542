import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';
import Typesense from 'typesense';
import styles from './Homepage.module.css';
import backgroundImage from './main.jpg';
import RewardsandStatusImage from '../images/RewardsandStatus.png';
import ProductPopup from './ProductPopup'; // Adjust the import path as necessary
import CookieAccept from './cookiepopup';
import { useCookies } from 'react-cookie';

const TRACKING_ID = "G-62GN7053MN"; // Your Google Analytics tracking ID

// Initialize Typesense client
const typesenseClient = new Typesense.Client({
  nodes: [{
    host: process.env.REACT_APP_TYPESENSE_HOST,
    port: process.env.REACT_APP_TYPESENSE_PORT,
    protocol: process.env.REACT_APP_TYPESENSE_PROTOCOL
  }],
  apiKey: process.env.REACT_APP_TYPESENSE_API_KEY,
  connectionTimeoutSeconds: 2
});

const usePageTracking = () => {
  const [cookies, ] = useCookies(['accepted']);
  const history = useHistory();

  useEffect(() => {
    if (cookies.accepted === 'true') {
      ReactGA.initialize(TRACKING_ID);
      const page = window.location.pathname + window.location.search;
      ReactGA.pageview(page);
    }

    const unlisten = history.listen((location) => {
      if (cookies.accepted === 'true') {
        const currentPage = location.pathname + location.search;
        ReactGA.set({ page: currentPage });
        ReactGA.pageview(currentPage);
      }
    });

    return unlisten;
  }, [history, cookies.accepted]);

};

const Section1 = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const history = useHistory();

  const handleSearch = () => {
    if (searchQuery.trim() !== '') {
      history.push(`/search?q=${encodeURIComponent(searchQuery)}`);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className={styles.section1} style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className={styles['search-bar']}>
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <button onClick={handleSearch}>🔍</button>
      </div>
    </div>
  );
};

const Section2 = () => (
  <div className={styles.section2}>
    <div className={styles['text-section']}>
      <h2>Explore Fashion</h2>
      <p>Web Search is designed for words, Product Search is designed for all products, Runway Rewards is designed for Fashion</p>
    </div>
    <div className={styles['text-section']}>
      <h2>Earn Rewards</h2>
      <p>Relish the rewards of expanding your style. Earn up to 17% cashback on purchases, putting money back in your pocket as your tastes evolve.</p>
    </div>
    <div className={styles['text-section']}>
      <h2>Unveil Sustainability</h2>
      <p>Our spotlight enables you to see sustainability factors like water use, emissions and fair trade data. Make informed choices aligned with your values.</p>
    </div>
  </div>
);

const TopBrands = () => (
  <div className={styles.topbrands}>
    <h2>Brands</h2>
    <div className={styles.brands}>
      <a href="/search?q=Scotch%20and%20Soda">
        <img className={styles.brandImage} src="https://logowik.com/content/uploads/images/scotch-soda3238.jpg" alt="Scotch and Soda" />
      </a>
      <a href="/search?q=Hurley">
        <img className={styles.brandImage} src="https://logowik.com/content/uploads/images/hurley2282.jpg" alt="Hurley" />
      </a>
      <a href="/search?q=7%20for%20all%20mankind">
        <img className={styles.brandImage} src="https://logowik.com/content/uploads/images/7-for-all-mankind6880.logowik.com.webp" alt="7 for all mankind" />
      </a>
      <a href="/search?q=Diesel">
        <img className={styles.brandImage} src="https://logowik.com/content/uploads/images/diesel8049.logowik.com.webp" alt="Diesel" />
      </a>
      <a href="/search?q=Aldo">
        <img className={styles.brandImage} src="https://logowik.com/content/uploads/images/aldo-group8341.jpg" alt="Aldo" />
      </a>
    </div>
  </div>
);

const Section14 = () => (
  <div className={styles.section14} style={{ backgroundImage: `url(${RewardsandStatusImage})` }}></div>
);

const Section15 = () => (
  <div className={styles.section15}>
    <div>Shop | Womens | Mens | Kids | Policy | Company</div>
    <div>Runway Rewards Shop</div>
    <address>New York, NY</address>
  </div>
);

// Utility function to format the price
const formatPrice = (price) => {
  const priceNumber = typeof price === 'number' ? price : parseFloat(price);
  return `$${priceNumber.toFixed(2)}`;
};

// Component to render individual cards
// Component to render individual cards
const ProductCard = ({ product }) => {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <>
      <div className={styles.card} onClick={() => setShowPopup(true)}>
        <img src={product.image_url1} alt={product.name} />
        <div className={styles.cardContent}>
          <h5>{product.brand}</h5>
          <p>{formatPrice(product.price_usd)}</p>
          <p>Rewards: {product["Base Rewards Percent"]}%</p>
        </div>
      </div>
      {/* Assuming ProductPopup is a modal or similar component */}
      {showPopup && (
        <ProductPopup product={product} onClose={() => setShowPopup(false)} />
      )}
    </>
  );
};

const CardSection = ({ title, ids }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchAllProductsByIds = async () => {
      try {
        const searchQueries = ids.map(id => ({
          q: id,
          query_by: 'ID',
          page: 1,
          per_page: 1,
          exhaustive_search: false
        }));

        const productPromises = searchQueries.map(query => 
          typesenseClient.collections(process.env.REACT_APP_TYPESENSE_COLLECTION_NAME)
            .documents().search(query)
        );

        const searchResults = await Promise.all(productPromises);
        const fetchedProducts = searchResults.map(result => result.hits[0].document);

        setProducts(fetchedProducts.filter(Boolean)); // Filter out any undefined products
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchAllProductsByIds();
  }, [ids]);


  // Function to chunk the products array into rows
  const chunkIntoRows = (products, itemsPerRow) => {
    const rows = [];
    for (let i = 0; i < products.length; i += itemsPerRow) {
      rows.push(products.slice(i, i + itemsPerRow));
    }
    return rows;
  };

  // Chunk the products array into rows of 5 items each
  const productRows = chunkIntoRows(products, 5);

  return (
    <div className={styles['cards-container']}>
      <h2>{title}</h2>
      <div className={styles.cards}>
        {productRows.map((rowProducts, idx) => (
          <div key={idx} className={styles['card-row']}>
            {rowProducts.map((product) => (
              <ProductCard key={product.id} product={product} />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

const Homepage = ({ onSearch }) => {
  usePageTracking();

  return (
    <div className={styles.app}>
      <Section1 />
      <Section2 />
      <TopBrands />
      <CardSection title="Winter 2023  Outerwear Coats" ids={['8818D', '7F879', '4EA5D', '35A2', 'A603E', '5A76B', '3C0AA', '3D0D9', '6BCE2', '6BCF1', '11099F']} />
      <CardSection title="Jackets" ids={['9068B', '51D24', '52AD7', '6E351', '569', '6E13D', '526B8', '6C9DB', '93BF4', 'E8C92', '56258', '815FA', 'E75D5']} />
      <CardSection title="Cozy Sweaters" ids={['939F9', '93960', '59D03', '9068B', '9A969', 'A5E2B', 'ABFBE', '59F79', '7C8E0', '5A7F4', '7E6A5']} />
      <CardSection title="Ready for Holiday Parties" ids={['1AE3D', '13AEC', '18332', '1285E4', 'ABCD7', '1AFBC', '1934D', '17F2B', '1834B', '1771E', '24234', '246FA', '10B2C3', '99A1']} />
      <CardSection title="Scarves" ids={['78755', '402D5', '9C063', '5B0DE', '5ABF0', 'AC13A']} />
      <CardSection title="Jewelry" ids={['9023B', '90239', 'AD9CA', '58BCD', 'AD8B9', '3DFAC', '5860A',  '7CA3A']} />
      <CookieAccept />
      <Section15 />
    </div>
  );
};

export default Homepage;
