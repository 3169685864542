import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { FaHeart } from 'react-icons/fa';
import Typesense from 'typesense';
import { doc, getDoc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { db } from '../firebase';
import styles from './WishlistDisplay.module.css';
import ProductPopup from './ProductPopup';

// Initialize Typesense client
const typesenseClient = new Typesense.Client({
  nodes: [{
    host: process.env.REACT_APP_TYPESENSE_HOST,
    port: process.env.REACT_APP_TYPESENSE_PORT,
    protocol: process.env.REACT_APP_TYPESENSE_PROTOCOL
  }],
  apiKey: process.env.REACT_APP_TYPESENSE_API_KEY,
  connectionTimeoutSeconds: 2
});

function WishlistDisplay() {
  const [activeTab, setActiveTab] = useState('WishList');
  const [wishlist, setWishlist] = useState([]);
  const [sharedWishlist, setSharedWishlist] = useState([]);
  const [publicWishlist, setPublicWishlist] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    fetchWishlists();
  }, [user]);



  const removeItem = async (item) => {
    if (user) {
      const listToRemoveFrom = activeTab.toLowerCase(); // Get the current active list
      const userRef = doc(db, 'Users', user.uid);
      await updateDoc(userRef, {
        [listToRemoveFrom]: arrayRemove(item.ID)
      });
    }
    fetchWishlists();
  };

  const fetchDetails = async (ids) => {
    const promises = ids.map(id => 
      typesenseClient.collections(process.env.REACT_APP_TYPESENSE_COLLECTION_NAME)
        .documents().search({
          q: id.toString(),
          query_by: 'ID',
          page: 1,
          per_page: 1
        })
    );

    const searchResults = await Promise.all(promises);
    return searchResults.map(result => result.hits.length > 0 ? result.hits[0].document : null).filter(Boolean);
  };

  const fetchWishlists = async () => {
    if (user) {
      // Firebase user data fetching
      const userRef = doc(db, 'Users', user.uid);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const wishlistIds = userDoc.data().wishlist || [];
        const sharedIds = userDoc.data().sharedWishlist || [];
        const publicIds = userDoc.data().publicWishlist || [];

        const [wishlistDetails, sharedDetails, publicDetails] = await Promise.all([
          fetchDetails(wishlistIds),
          fetchDetails(sharedIds),
          fetchDetails(publicIds),
        ]);

        setWishlist(wishlistDetails);
        setSharedWishlist(sharedDetails);
        setPublicWishlist(publicDetails);
      }
    } else {
      // Local storage handling
      const localWishlist = JSON.parse(localStorage.getItem('wishlist')) || [];
      const localSharedWishlist = JSON.parse(localStorage.getItem('sharedWishlist')) || [];
      const localPublicWishlist = JSON.parse(localStorage.getItem('publicWishlist')) || [];

      setWishlist(localWishlist);
      setSharedWishlist(localSharedWishlist);
      setPublicWishlist(localPublicWishlist);
    }
  };

  const moveItem = async (fromList, toList, item) => {
    if (user) {
      // Firebase user data updating
      const userRef = doc(db, 'Users', user.uid);
      await updateDoc(userRef, {
        [fromList]: arrayRemove(item.ID),
        [toList]: arrayUnion(item.ID)
      });
      fetchWishlists();
    } else {
      // Local storage handling
      const fromListItems = JSON.parse(localStorage.getItem(fromList)) || [];
      const toListItems = JSON.parse(localStorage.getItem(toList)) || [];
      
      // Remove item from fromList and add to toList
      const updatedFromListItems = fromListItems.filter(listItem => listItem.ID !== item.ID);
      const updatedToListItems = [...toListItems, item];

      localStorage.setItem(fromList, JSON.stringify(updatedFromListItems));
      localStorage.setItem(toList, JSON.stringify(updatedToListItems));

      // Update state
      setWishlist(fromList === 'wishlist' ? updatedFromListItems : wishlist);
      setSharedWishlist(fromList === 'sharedWishlist' ? updatedFromListItems : sharedWishlist);
      setPublicWishlist(fromList === 'publicWishlist' ? updatedFromListItems : publicWishlist);
    }
  };

  const displayItems = (items, fromList) => {
    return items.map(item => (
      <div key={item.ID} className={styles.card}>
  
        <FaHeart 
          className="wl-wishlist-icon"
          onClick={() => removeItem(item)}  
        />
  
        <div className={styles.hoverOptions}>
          {fromList !== 'wishlist' && (
            <button onClick={() => moveItem(fromList, 'wishlist', item)}>Move to Wishlist</button>
          )}
          {fromList !== 'sharedWishlist' && (
            <button onClick={() => moveItem(fromList, 'sharedWishlist', item)}>Move to Shared</button>
          )}
          {fromList !== 'publicWishlist' && (
            <button onClick={() => moveItem(fromList, 'publicWishlist', item)}>Move to Public</button>
          )}
        </div>
  
        <img src={item.image_url1} className={styles.image} />
  
        <p className={styles.name}>  
          {item.name}
        </p>
  
        <p className={styles.price}>
          {item.price_usd}  
        </p>
  
      </div>
    ));
  };

  return (
    <div className={styles.wishlistContainer}>
      <div className={styles.tabs}>
        <button onClick={() => setActiveTab('WishList')} className={activeTab === 'WishList' ? styles.active : ''}>WishList</button>
        <button onClick={() => setActiveTab('Shared Wishlist')} className={activeTab === 'Shared Wishlist' ? styles.active : ''}>Shared Wishlist</button>
        <button onClick={() => setActiveTab('Public Wishlist')} className={activeTab === 'Public Wishlist' ? styles.active : ''}>Public Wishlist</button>
      </div>
      <div className={styles.gridContainer}>
        {activeTab === 'WishList' && displayItems(wishlist, 'wishlist')}
        {activeTab === 'Shared Wishlist' && displayItems(sharedWishlist, 'sharedWishlist')}
        {activeTab === 'Public Wishlist' && displayItems(publicWishlist, 'publicWishlist')}
      </div>
      {showPopup && selectedProduct && (
        <ProductPopup product={selectedProduct} onClose={() => setShowPopup(false)} />
      )}
    </div>
  );
}

export default WishlistDisplay;
