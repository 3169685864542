import React, { useState, useEffect, createContext } from 'react';
import axios from 'axios';
import styles from './AuthHeader.module.css';
import { useLocation, Link } from 'react-router-dom';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import { db } from '../firebase'; // Ensure this is the correct path to your Firebase config


// Create and export AuthContext
export const AuthContext = createContext(null);

const AuthHeader = () => {
  const [user, setUser] = useState(null);
  const [menuData, setMenuData] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    axios.get('/menu-data-1.json')
      .then(response => {
        setMenuData(response.data);
      })
      .catch(error => {
        console.error("Error fetching menu data:", error);
      });
  }, []);

  const handleSignInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
  
      // Check if the user exists in the Firestore database
      const userDocRef = doc(db, 'Users', user.uid);
      const userDoc = await getDoc(userDocRef);
  
      if (!userDoc.exists()) {
        // Create a new user document in Firestore
        await setDoc(userDocRef, {
          memberID: user.uid, // or another unique ID
          memberSince: new Date(), // current date as sign-up date
          currentTier: 'Lifetime Fashion Visionary', // set the tier
          accumulatedPoints: 0, // initial points
          // ... (add more fields as necessary, e.g., name, email)
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error(error);
    }
  };

  const handleProfileImageClick = () => {
    setDropdownVisible(!dropdownVisible);
  };

  return (
    <AuthContext.Provider value={user}>
      <div className={styles['top-section']}>
        <div className={styles.left}>
          <img src="/logo192.png" alt="Runway Logo" className={styles.logo} />
          <Link to="/" className={`${styles.brand} ${styles.noLinkStyle}`}>Runway Rewards Shop</Link>
          {/* Shop Now Dropdown moved here */}
          <div className={styles.dropdown}>
            <div className={styles.dropdownContent}>
              {menuData?.map((data) => (
                <div className={styles.dropdownSection} key={data.category}>
                  <h1>{data.category}</h1>
                  {data.items?.map((item) => (
                    <Link to={`/search?q=${item.toLowerCase()}`} key={item}>{item}</Link>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.right}>
          {user ? (
            <div onClick={handleProfileImageClick} className={styles.profileContainer}>
              <img src={user.photoURL} alt="User Profile" className={styles.profileImage} />
              {dropdownVisible && (
                <div className={styles.dropdownMenu}>
                  <Link to="/profile">Profile</Link>
                  <Link to="/wishlist">Wishlist</Link>
                  <a href="/" onClick={handleLogout}>Logout</a>
                </div>
              )}
            </div>
          ) : (
            <button onClick={handleSignInWithGoogle} className={`${styles.button} ${styles.authButton}`}>Sign Up</button>
          )}
        </div>
      </div>
    </AuthContext.Provider>
  );
};

export default AuthHeader;
