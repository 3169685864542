import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import styles from './Profile.module.css';

const Profile = () => {
  const [profileData, setProfileData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, 'Users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setProfileData(userDoc.data());
        }
      }
    };
    fetchData();
  }, []);

  const spendToNextTier = 250 - (profileData ? profileData.accumulatedPoints : 0);

  return (
    <div className={styles.profileContainer}>
      {profileData ? (
        <div className={styles.profileContent}>
          <h1>Profile</h1>
          <p>Member ID: {profileData.memberID}</p>
          <p>Member Since: {new Date(profileData.memberSince).getFullYear()}</p>
          <p>Current Tier: {profileData.currentTier}</p>
          <p>Point Balance: {profileData.accumulatedPoints}</p>
          <p>Spend to Next Tier: ${spendToNextTier}</p>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default Profile;
