import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../AuthContext';
import SizeAndFitDetails from './SizeAndFitDetails'; // Adjust the import path as necessary
import SustainabilityDetails from './SustainabilityDetails';
import Retool from 'react-retool';
import './ProductPopup.css';

function ProductPopup({ product, onClose }) {
  const [selectedImage, setSelectedImage] = useState(product.image_url1 || '');
  const [expandedSection, setExpandedSection] = useState(null);
  const [retoolVisible, setRetoolVisible] = useState(false);
  const { user } = useAuth();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [sizeAndFitText, setSizeAndFitText] = useState('');
  const [sustainabilityText, setImpactText] = useState('');
  const imageUrls = [product.image_url1, product.image_url2, product.image_url3, product.image_url4, product.image_url5, product.image_url6].filter(url => url);
  const [loading, setLoading] = useState(true);


  // Handlers for next and previous buttons
  const handleNextClick = () => {
   setCurrentIndex((currentIndex + 1) % imageUrls.length); // Go to next image, wrap to first if at the end
 };

 const handlePrevClick = () => {
   setCurrentIndex((currentIndex - 1 + imageUrls.length) % imageUrls.length); // Go to previous image, wrap to last if at the start
 };


 useEffect(() => {
   if (imageUrls.length > 0) {
     setSelectedImage(imageUrls[currentIndex]);
   }
 }, [currentIndex, imageUrls]);



  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const headers = {
          'Content-Type': 'application/json; charset=utf-8',
        };

        const sizeAndFitResponse = await axios.get(
          `https://bard.runwayrewards.shop/sizing?user_input=${encodeURIComponent(product.name)}`,
          {
            headers,
          }
        );

        const sizeAndFitResponseData = sizeAndFitResponse.data.response;
        setSizeAndFitText(sizeAndFitResponseData);

        const impactResponse = await axios.get(
          `https://bard.runwayrewards.shop/impact?user_input=${encodeURIComponent(product.name)}`,
          {
            headers,
          }
        );

        const impactResponseData = impactResponse.data.response;
        setImpactText(impactResponseData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [product.name]);

  const formatPrice = (price) => {
    const priceNumber = typeof price === 'number' ? price : parseFloat(price);
    return `$${priceNumber.toFixed(2)}`;
  };

  const getAffiliateLink = (url, network, userId, advertiserId) => { 
    console.log('Original URL:', url); 
    console.log('Network:', network); 
    console.log('UserID:', userId); 
    console.log('Advertiser ID:', advertiserId); 
          
    let updatedUrl = url; 
    switch (network) { 
        case 'AWIN': 
            updatedUrl = url.replace(/(awinmid=[^&]+&awinaffid=[^&]+)(.*)/, `$1${userId ? '&clickref=' + userId : ''}$2`); 
            break; 
        case 'CJ': 
            updatedUrl = url.replace(/(click-[^-]+-[^?]+)\?/, `$1${userId ? '?sid=' + userId : ''}&`); 
            break; 
        case 'RAK':
          let murl = new URLSearchParams(url.split('?')[1] || '').get('murl');
          if (!murl) {
              console.error('Unable to extract murl from the given url', url);
              break;
          }
          updatedUrl = `https://click.linksynergy.com/deeplink?id=ZXkZxh*1HDA&mid=${advertiserId}&murl=${encodeURIComponent(murl)}`;
          if (userId) {
              updatedUrl += `&u1=${userId}`;
          }
              break; 
        default: 
            // Leave the url as it is 
            break; 
    } 
    return updatedUrl; 
};

// note the addition of `product.advertiserid` to the `getAffiliateLink` call
const affiliateLink = getAffiliateLink( 
  product.link, 
  product.network, 
  user?.uid, 
  product.advertiserId 
);

return (
  <div className="product-popup">
     <div className="popup-close" onClick={onClose}>X</div>
     <div className="popup-content">
        <div className="top-section">
              <div className="image-section">
            <button className="arrow left-arrow" onClick={handlePrevClick}>
              &lt; {/* Left arrow text or SVG icon */}
            </button>
              <img src={selectedImage} alt="Selected Product Image" />
            <button className="arrow right-arrow" onClick={handleNextClick}>
              &gt; {/* Right arrow text or SVG icon */}
            </button>
          </div>
           <div className="non-expandable-details">
              <div className="popup-header">
                 <span>{product.brand}</span>
                 <span>{product.category}</span>
              </div>
              <div className="product-name">{product.name}</div>
              <div className="price-and-reward">
                 <div className="medium-text">{formatPrice(product.price_usd)}</div>
                 <div className="medium-text base-reward">Base Reward: {product["baserewardpct"]}%</div>
              </div>
              <div className="medium-text">{product.cashback}</div>
           </div>
        </div>
        {/* Personalized Sizing Tool Section */}
        <div className="expandable-section">
           <div className="section-header" onClick={() => setRetoolVisible(!retoolVisible)}>
              <div className="section-title">Personalized Sizing Tool</div>
              <div className="expand-icon">{retoolVisible ? '-' : '+'}</div>
           </div>
           {retoolVisible && (
              <div className="section-content">
                 <Retool
                    url="https://runwayrewards.retool.com/embedded/public/9f1bbb39-c1dc-4abb-a3f8-011d7b2be023"
                    data={{ productName: product.name, productSize: product.size }}
                 />
              </div>
           )}
        </div>
        <div className="details-section">
           {['Size and Fit', 'Description and Style Recommendations', 'Materials, Impact, and Sustainability Information'].map((section) => (
              <div key={section} className="expandable-section">
                 <div className="section-header" onClick={() => setExpandedSection(section === expandedSection ? null : section)}>
                    <div className="section-title">{section}</div>
                    <div className="expand-icon">{section === expandedSection ? '-' : '+'}</div>
                 </div>
                 {section === expandedSection && (
                    <div className="section-content">
                       {section === 'Description and Style Recommendations' ? product.description : ''}
                       {section === 'Materials, Impact, and Sustainability Information' && (
                          <div>
                             <Retool
                                url="https://runwayrewards.retool.com/embedded/public/ca57a994-d311-4aa7-bf72-9bbfe91a752f"
                                data={{ productName: product.name, productBrand: product.brand, productDescription: product.description }}
                             />
                          </div>
                       )}
                       {section === 'Size and Fit' && <SizeAndFitDetails sizeAndFitText={sizeAndFitText} />}
                    </div>
                 )}
              </div>
           ))}
           <a href={`/item/${product._id}`} className="primary-button">
              View Full Product Page
           </a>
           <a href={affiliateLink} className="secondary-button" target="_blank" rel="noopener noreferrer">
              {user ? 'Buy with CashBack' : 'Buy Now from Store'}
           </a>
        </div>
     </div>
  </div>
);
}
export default ProductPopup;