import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../AuthContext';
import SizeAndFitDetails from './SizeAndFitDetails'; // Adjust the import path as necessary
import SustainabilityDetails from './SustainabilityDetails';
import Retool from 'react-retool';
import './ProductPopup.css';

function ProductPopup({ product, onClose }) {
  const [selectedImage, setSelectedImage] = useState(product.image_url1 || '');
  const [expandedSection, setExpandedSection] = useState(null);
  const [retoolVisible, setRetoolVisible] = useState(false);
  const { user } = useAuth();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [sizeAndFitText, setSizeAndFitText] = useState('');
  const [sustainabilityText, setImpactText] = useState('');
  const imageUrls = product ? [1, 2, 3, 4, 5, 6].map(idx => product[`image_url${idx}`]).filter(img => img) : [];
  console.log(product);



  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const headers = {
          'Content-Type': 'application/json; charset=utf-8',
        };

        const sizeAndFitResponse = await axios.get(
          `https://bard.runwayrewards.shop/sizing?user_input=${encodeURIComponent(product.name)}`,
          {
            headers,
          }
        );

        const sizeAndFitResponseData = sizeAndFitResponse.data.response;
        setSizeAndFitText(sizeAndFitResponseData);

        const impactResponse = await axios.get(
          `https://bard.runwayrewards.shop/impact?user_input=${encodeURIComponent(product.name)}`,
          {
            headers,
          }
        );

        const impactResponseData = impactResponse.data.response;
        setImpactText(impactResponseData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [product.name]);

  const formatPrice = (price) => {
    const priceNumber = typeof price === 'number' ? price : parseFloat(price);
    return `$${priceNumber.toFixed(2)}`;
  };

  const handleNextClick = () => {
    console.log("Next clicked");
    setCurrentIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
  };
  
  const handlePrevClick = () => {
    console.log("Prev clicked");
    setCurrentIndex((prevIndex) => (prevIndex - 1 + imageUrls.length) % imageUrls.length);
  };

  const handleImageSectionClick = (e) => {
    const bounds = e.target.getBoundingClientRect();
    const x = e.clientX - bounds.left; // Click X position within the element
    const width = bounds.right - bounds.left;
  
    if(x < width / 2) {
      handlePrevClick(); // Clicked on the left side, assuming left arrow click
    } else {
      handleNextClick(); // Clicked on the right side, assuming right arrow click
    }
  };

  

  const changeImage = (direction) => {
    // Array of available image URLs from the product
    const imageUrls = [product.image_url1, product.image_url2, product.image_url3, product.image_url4, product.image_url5, product.image_url6].filter(Boolean);
    console.log(imageUrls)
    let newIndex = imageUrls.indexOf(selectedImage) + direction;
    // Wrap around navigation
    if (newIndex < 0) newIndex = imageUrls.length - 1;
    else if (newIndex >= imageUrls.length) newIndex = 0;
    
    setSelectedImage(imageUrls[newIndex]);
  };

  const getAffiliateLink = (url, network, userId) => {
    if (!userId) {
      return url;
    }

    switch (network) {
      case 'AWIN':
        return url.replace(/(awinmid=[^&]+&awinaffid=[^&]+)(.*)/, `$1&clickref=${userId}$2`);
      case 'CJ':
        return url.replace(/(click-[^-]+-[^?]+)\?/, `$1?sid=${userId}&`);
      case 'RAK':
        const urlParts = url.split('?');
        const queryParams = new URLSearchParams(urlParts[1] || '');
        queryParams.set('u1', userId);
        return `${urlParts[0]}?${queryParams.toString()}`;
      default:
        return url;
    }
  };

  const affiliateLink = getAffiliateLink(product.link, product.Network, user?.uid);

  return (
    <div className="product-popup">
      <div className="popup-close" onClick={onClose}>
        X
      </div>
      <div className="popup-content">
        <div className="top-section">
        <div className="image-section">
  {imageUrls.length > 0 && (
    <>
      <button className="arrow left-arrow" onClick={handlePrevClick}>
        <svg viewBox="0 0 24 24" fill="currentColor" width="24px" height="24px"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path></svg>
      </button>
      <img src={imageUrls[currentIndex]} alt={product ? `${product.description}` : ''} />
      <button className="arrow right-arrow" onClick={handleNextClick}>
        <svg viewBox="0 0 24 24" fill="currentColor" width="24px" height="24px"><path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6z"></path></svg>
      </button>
    </>
  )}
</div>
          <div className="non-expandable-details">
            <div className="popup-header">
              <span>{product.brand}</span>
              <span>{product.category}</span>
            </div>
            <div className="product-name">{product.name}</div>
            <div className="price-and-reward">
              <div className="medium-text">{formatPrice(product.price_usd)}</div>
              <div className="medium-text base-reward">Base Reward: {product["Base Rewards Percent"]}%</div>
            </div>
            <div className="medium-text">{product.cashback}</div>
            <div className="placeholder">Available Colors/Styles: {product.color || 'N/A'}</div>
            <div className="placeholder">Available Sizes: {product.size || 'N/A'}</div>
          </div>
        </div>

        {/* Personalized Sizing Tool Section */}
        <div id="productName" hidden>{`{"productName": "${product?.name || ''}", "productSize": "${product?.size || ''}"}`}</div>

        {/* Retool component with updated data */}
        <div className="expandable-section">
          <div className="section-header" onClick={() => setRetoolVisible(!retoolVisible)}>
            <div className="section-title">Personalized Sizing Tool</div>
            <div className="expand-icon">{retoolVisible ? '-' : '+'}</div>
          </div>
          {retoolVisible && (
            <div className="section-content">
              <Retool
                url="https://runwayrewards.retool.com/embedded/public/9f1bbb39-c1dc-4abb-a3f8-011d7b2be023"
                data={{ productName: product.name, productSize: product.size }}
              />
            </div>
          )}
        </div>

        <div className="details-section">
          {loading ? (
            <div>Loading...</div>
          ) : (
            ['Size and Fit', 'Description and Style Recommendations', 'Materials, Impact, and Sustainability Information'].map((section) => (
              <div key={section} className="expandable-section">
                <div className="section-header" onClick={() => setExpandedSection(section === expandedSection ? null : section)}>
                  <div className="section-title">{section}</div>
                  <div className="expand-icon">{section === expandedSection ? '-' : '+'}</div>
                </div>
                {section === expandedSection && (
                  <div className="section-content">
                    {section === 'Description and Style Recommendations' ? product.description : ''}
                    {section === 'Materials, Impact, and Sustainability Information' && (
                      <SustainabilityDetails sustainabilityText={sustainabilityText} />
                    )}
                    {section === 'Size and Fit' && <SizeAndFitDetails sizeAndFitText={sizeAndFitText} />}
                  </div>
                )}
              </div>
            ))
          )}
          <a href={`/product/${product.ID}`} className="primary-button">
            View Full Product Page
          </a>
          <a href={affiliateLink} className="secondary-button" target="_blank" rel="noopener noreferrer">
            {user ? 'Buy with CashBack' : 'Buy Now from Store'}
          </a>
        </div>
      </div>
    </div>
  );
}

export default ProductPopup;
