import React, { useState } from 'react';
import styles from './SearchFilters.module.css';

function SearchFilters({ facets, onFilterChange }) {
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedGenders, setSelectedGenders] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedOccasions, setSelectedOccasions] = useState([]);
  const [showSubCategoriesDropdown, setShowSubCategoriesDropdown] = useState(false);
  const [showBrandsDropdown, setShowBrandsDropdown] = useState(false);
  const [showGendersDropdown, setShowGendersDropdown] = useState(false);
  const [showColorsDropdown, setShowColorsDropdown] = useState(false);
  const [sortByPrice, setSortByPrice] = useState(false);

  const handleSortChange = (e) => {
    setSortByPrice(e.target.checked);
    onFilterChange('sortByPrice', e.target.checked);
  };
  

  const handleSubCategoryChange = (e) => {
    const subCategory = e.target.value;
    if (selectedSubCategories.includes(subCategory)) {
      const newSelectedSubCategories = selectedSubCategories.filter((sc) => sc !== subCategory);
      setSelectedSubCategories(newSelectedSubCategories);
      onFilterChange('sub_category', newSelectedSubCategories);
    } else {
      const newSelectedSubCategories = [...selectedSubCategories, subCategory];
      setSelectedSubCategories(newSelectedSubCategories);
      onFilterChange('sub_category', newSelectedSubCategories);
    }
  };

  const handleBrandChange = (e) => {
    const brand = e.target.value;
    if (selectedBrands.includes(brand)) {
      const newSelectedBrands = selectedBrands.filter((b) => b !== brand);
      setSelectedBrands(newSelectedBrands);
      onFilterChange('brand', newSelectedBrands);
    } else {
      const newSelectedBrands = [...selectedBrands, brand];
      setSelectedBrands(newSelectedBrands);
      onFilterChange('brand', newSelectedBrands);
    }
  };

  const handleGenderChange = (e) => {
    const gender = e.target.value;
    if (selectedGenders.includes(gender)) {
      const newSelectedGenders = selectedGenders.filter((g) => g !== gender);
      setSelectedGenders(newSelectedGenders);
      onFilterChange('gender', newSelectedGenders);
    } else {
      const newSelectedGenders = [...selectedGenders, gender];
      setSelectedGenders(newSelectedGenders);
      onFilterChange('gender', newSelectedGenders);
    }
  };

  const handleColorChange = (e) => {
    const color = e.target.value;
    if (selectedColors.includes(color)) {
      const newSelectedColors = selectedColors.filter((c) => c !== color);
      setSelectedColors(newSelectedColors);
      onFilterChange('color_', newSelectedColors);
    } else {
      const newSelectedColors = [...selectedColors, color];
      setSelectedColors(newSelectedColors);
      onFilterChange('color_', newSelectedColors);
    }
  };

  const handleOccasionChange = (e) => {
    const occasion = e.target.value;
    if (selectedOccasions.includes(occasion)) {
      const newSelectedOccasions = selectedOccasions.filter((o) => o !== occasion);
      setSelectedOccasions(newSelectedOccasions);
      onFilterChange('occasion', newSelectedOccasions);
    } else {
      const newSelectedOccasions = [...selectedOccasions, occasion];
      setSelectedOccasions(newSelectedOccasions);
      onFilterChange('occasion', newSelectedOccasions);
    }
  };


  return (
    <div className={styles.searchFilters}>
      <div onMouseEnter={() => setShowSubCategoriesDropdown(true)} onMouseLeave={() => setShowSubCategoriesDropdown(false)}>
        <button>Categories</button>
        {showSubCategoriesDropdown && (
          <div className={styles.dropdown}>
            {Object.keys(facets.sub_category || {}).sort().map((subCategory) => (
              <label key={subCategory}>
                <input
                  type="checkbox"
                  value={subCategory}
                  checked={selectedSubCategories.includes(subCategory)}
                  onChange={handleSubCategoryChange}
                />
                {subCategory}
              </label>
            ))}
          </div>
        )}
      </div>

      <div onMouseEnter={() => setShowBrandsDropdown(true)} onMouseLeave={() => setShowBrandsDropdown(false)}>
        <button>Brands</button>
        {showBrandsDropdown && (
          <div className={styles.dropdown}>
            {Object.keys(facets.brand || {}).sort().map((brand) => (
              <label key={brand}>
                <input
                  type="checkbox"
                  value={brand}
                  checked={selectedBrands.includes(brand)}
                  onChange={handleBrandChange}
                />
                {brand}
              </label>
            ))}
          </div>
        )}
      </div>

      <div onMouseEnter={() => setShowGendersDropdown(true)} onMouseLeave={() => setShowGendersDropdown(false)}>
        <button>Gender</button>
        {showGendersDropdown && (
          <div className={styles.dropdown}>
            {Object.keys(facets.gender || {}).sort().map((gender) => (
              <label key={gender}>
                <input
                  type="checkbox"
                  value={gender}
                  checked={selectedGenders.includes(gender)}
                  onChange={handleGenderChange}
                />
                {gender}
              </label>
            ))}
          </div>
        )}
      </div>

      <div onMouseEnter={() => setShowColorsDropdown(true)} onMouseLeave={() => setShowColorsDropdown(false)}>
        <button>Color</button>
        {showColorsDropdown && (
          <div className={styles.dropdown}>
            {Object.keys(facets.color_ || {}).sort().map((color) => (
              <label key={color}>
                <input
                  type="checkbox"
                  value={color}
                  checked={selectedColors.includes(color)}
                  onChange={handleColorChange}
                />
                {color}
              </label>
            ))}
          </div>
        )}
      </div>

    </div>
  );
}

export default SearchFilters;
