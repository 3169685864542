import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Configure, connectSearchBox } from 'react-instantsearch-dom';
import SearchBar from './SearchBarAlgolia';
import SearchFilters from './SearchFilters';
import ProductPopup from './ItemPopup';
import styles from './SearchResultsPage.module.css';
import aa from 'search-insights';

const client = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_SEARCH_KEY
);

aa('init', {
  appId: process.env.REACT_APP_ALGOLIA_APP_ID,
  apiKey: process.env.REACT_APP_ALGOLIA_SEARCH_KEY
});

const index = client.initIndex('RunwayRewardsDataLake2024V2');

const CustomSearchBox = ({ currentRefinement, refine }) => {
  return (
    <SearchBar
      value={currentRefinement}
      onChange={(e) => refine(e.target.value)}
    />
  );
};

const ConnectedSearchBox = connectSearchBox(CustomSearchBox);

function ClothingItem({ item, onClick }) {
  return (
    <div className={styles.card} onClick={() => onClick(item)}>
      <img src={item.image_url1} alt={item.name || item.ID} className={styles.thumbnail} />
      <div className={styles.itemDetails}>
        <div className={styles.textContainer}></div>
        {item.price_usd && (
          <div className={styles.priceContainer}>
            <p className={styles.price}>${item.price_usd.toFixed(0)}</p>
          </div>
        )}
      </div>
    </div>
  );
}

function ContentGrid({ items, onClick }) {
  return (
    <div className={styles.grid}>
      {items.map((item) => (
        <div key={item.ID} className={styles.item}>
          <ClothingItem item={item} onClick={onClick} />
        </div>
      ))}
    </div>
  );
}

function SearchResultsPage() {
  const [sortByPrice, setSortByPrice] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [clothingItems, setClothingItems] = useState([]);
  const [facets, setFacets] = useState({});
  const [filters, setFilters] = useState({
    category: '',
    brand: '',
    gender: '',
    color_: '',
    priceRange: {
      min: '',
      max: '',
    },
    occasion: '',
    sub_category: '',
  });
  const [selectedProduct, setSelectedProduct] = useState(null);

  const location = useLocation();
  const query = new URLSearchParams(location.search).get('q');
  const genderFilter = new URLSearchParams(location.search).get('gender');
  const categoryFilter = new URLSearchParams(location.search).get('category');
  const occasionFilter = new URLSearchParams(location.search).get('occasion');
  const subCategoryFilter = new URLSearchParams(location.search).get('sub_category');

  useEffect(() => {
    setSearchTerm(query);
    const fetchSearchResults = async () => {
      const facetFilters = Object.entries(filters)
        .filter(([, value]) => value)
        .map(([facet, value]) => {
          if (facet === 'priceRange') {
            const minPrice = value.min ? [`price_usd>=${value.min}`] : [];
            const maxPrice = value.max ? [`price_usd<=${value.max}`] : [];
            return [...minPrice, ...maxPrice];
          } else if (
            facet === 'category' ||
            facet === 'brand' ||
            facet === 'gender' ||
            facet === 'color_' ||
            facet === 'sub_category'
          ) {
            if (Array.isArray(value)) {
              return value.map((v) => `${facet}:${v}`);
            } else {
              return [`${facet}:${value}`];
            }
          }
        })
        .flat();

      const { hits, facets } = await index.search(query, {
        hitsPerPage: 500,
        facets: [
          'brand',
          'category',
          'gender',
          'color_',
          'price_usd',
          'sub_category',
        ],
        facetFilters,
        numericSort: sortByPrice ? ['price_usd:asc'] : undefined,
      });

      const groupedByCategory = hits.reduce((groups, item) => {
        const category = item.category || 'uncategorized';
        if (!groups[category]) groups[category] = [];
        groups[category].push(item);
        return groups;
      }, {});

      const alternatedList = [];
      let done = false;
      while (!done) {
        done = true;
        for (const category in groupedByCategory) {
          if (groupedByCategory[category].length > 0) {
            alternatedList.push(groupedByCategory[category].shift());
            done = false;
          }
        }
      }
      setClothingItems(alternatedList);
      setFacets(facets || {});
    };
    fetchSearchResults();
  }, [
    query,
    filters,
    sortByPrice,
    genderFilter,
    categoryFilter,
    occasionFilter,
    subCategoryFilter,
  ]);

  function handleFilterChange(filterName, value) {
    if (filterName === 'sortByPrice') {
      setSortByPrice(value);
    } else {
      setFilters((prevFilters) => {
        if (filterName === 'minPrice' || filterName === 'maxPrice') {
          return {
            ...prevFilters,
            priceRange: {
              ...prevFilters.priceRange,
              [filterName]: value,
            },
          };
        }
        return {
          ...prevFilters,
          [filterName]: value,
        };
      });
    }
  }

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      gender: genderFilter || prevFilters.gender,
      category: categoryFilter || prevFilters.category,
      occasion: occasionFilter || prevFilters.occasion,
      sub_category: subCategoryFilter || prevFilters.sub_category,
    }));
  }, [genderFilter, categoryFilter, occasionFilter, subCategoryFilter]);

  return (
    <div className={styles.container}>
      <InstantSearch
        indexName="RunwayRewardsDataLake2024V2"
        searchClient={client}
        insights={{
          enabled: true,
        }}
      >
        <Configure hitsPerPage={500} />
        <ConnectedSearchBox />
        <SearchFilters facets={facets} onFilterChange={handleFilterChange} />
        <ContentGrid
          items={clothingItems}
          onClick={(item) => {
            setSelectedProduct(item);
            aa('clickedObjectIDsAfterSearch', {
              index: 'RunwayRewardsDataLake2024V1',
              eventName: 'Product Clicked',
              objectIDs: [item.objectID],
            });
            console.log('Product Clicked event sent:', item.objectID);
          }}
        />
        {selectedProduct && (
          <ProductPopup
            product={selectedProduct}
            onClose={() => setSelectedProduct(null)}
          />
        )}
      </InstantSearch>
    </div>
  );
}

export default SearchResultsPage;