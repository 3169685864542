import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import styles from './SearchBar.module.css';

const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isFocused, setIsFocused] = useState(false); // Track focus state
  const history = useHistory();

  const handleSearch = (event) => {
    event.preventDefault();
    if (searchTerm) {
      console.log(`Search term submitted: ${searchTerm}`);
      history.push(`/search?q=${searchTerm}`);
      setIsFocused(false); // Reset focus state after search is executed
    }
  };

  const handleFocus = () => {
    console.log("Input focused");
    setIsFocused(true);
  };

  const handleBlur = () => {
    console.log("Input blurred");
    setIsFocused(false);
  };

  return (
    // Container for the search bar with dark blue background
    <div className={styles.searchBarContainer}>
      <form onSubmit={handleSearch} className={styles.searchBar}>
        <div className={`${styles.inputContainer} ${isFocused ? styles.focused : ''}`}>
          <input
            type="text"
            placeholder="Search "
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.target.value)}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={styles.input}
          />
          <button type="submit" className={styles.searchIcon}>
            <FaSearch color="black" />
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchBar;
