import React from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ProductPage from './components/ProductPageTypesense';
import ItemPage from './components/ProductPageAlgolia';
import AuthHeader from './components/AuthHeader';
import Footer from './components/Footer/Footer';
import TermsOfUse from './components/Legal/TermsofUse';
import PrivacyPolicy from './components/Legal/PrivacyPolicy';
import CookiePolicy from './components/Legal/CookiePolicy';
import Homepage from './components/Homepage';
import Profile from './components/Profile';
import WishlistDisplay from './components/WishlistDisplay';
import { AuthProvider } from './AuthContext';
import TypeSenseSearch from './components/TypeSenseSearch'; // TypeSenseSearch component
import TypeSenseSearchColorSizeTest from './components/TypeSenseSearchColorSizeTest';
import AlgoliaSearch from './components/SeachResultsAlgolia'; // AlgoliaSearch component
import birkininvestment from './components/Blog/birkininvestment'
import landingalpha from './components/landingalpha'
import landingbeta from './components/landingbeta'
import landinggamma from './components/landinggamma'
import landingdelta from './components/landingdelta'
import landingbags from './components/landingbags'


import './App.css';

function App() {
  return (
    <Router>
      <HelmetProvider>
        <Helmet>
          {/* Helmet configuration */}
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-62GN7053MN"></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-62GN7053MN');
            `}
          </script>
        </Helmet>
        <AuthProvider>
          <AuthHeader />
          <main className="mainContent">
            <Switch>
              <Route exact path="/" component={landingalpha} />
              <Route path="/typesensesearch" component={TypeSenseSearch} />
              <Route path="/searchcolorsize" component={TypeSenseSearchColorSizeTest} />
              <Route path="/product/:id" component={ProductPage} />
              <Route path="/item/:id" component={ItemPage} />
              <Route path="/terms-of-use" component={TermsOfUse} />
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route path="/cookie-policy" component={CookiePolicy} />
              <Route path="/birkin" component={birkininvestment} />
              <Route path="/profile" component={Profile} />
              <Route path="/wishlist" component={WishlistDisplay} />
              <Route path="/search" component={AlgoliaSearch} />
              <Route path="/landinga" component={landingalpha} />
              <Route path="/landingb" component={landingbeta} />
              <Route path="/landingc" component={landinggamma} />
              <Route path="/landingd" component={landingdelta} />
              <Route path="/handbags" component={landingbags} />
            </Switch>
          </main>
          <Footer />
        </AuthProvider>
      </HelmetProvider>
    </Router>
  );
}

export default App;
