import { createContext, useContext, useEffect, useState } from 'react';
import { 
  getAuth, 
  onAuthStateChanged, 
  GoogleAuthProvider, 
  signInWithPopup, 
  signOut, 
  setPersistence,  // <-- Import this
} from 'firebase/auth';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const authInstance = getAuth();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(authInstance, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, [authInstance]);

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      // Set local persistence before signing in
      await setPersistence(authInstance, 'local');  // <-- Use 'local' directly
      await signInWithPopup(authInstance, provider);
    } catch (error) {
      console.error(error);
    }
  };

  const logout = async () => {
    try {
      await signOut(authInstance);
    } catch (error) {
      console.error(error);
    }
  };

  const value = {
    user,
    signInWithGoogle,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
