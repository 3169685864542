import React, { useEffect, useState } from 'react';
import Typesense from 'typesense';
import Retool from 'react-retool';
import { useAuth } from '../AuthContext';
import SizeAndFitDetails from './SizeAndFitDetails'; // Adjust the import path as necessary
import SustainabilityDetails from './SustainabilityDetails';
import './ProductPopup.css';
import {Helmet} from "react-helmet";

const typesenseClient = new Typesense.Client({
  nodes: [
    {
      host: process.env.REACT_APP_TYPESENSE_HOST,
      port: process.env.REACT_APP_TYPESENSE_PORT,
      protocol: process.env.REACT_APP_TYPESENSE_PROTOCOL,
    },
  ],
  apiKey: process.env.REACT_APP_TYPESENSE_API_KEY,
  connectionTimeoutSeconds: 2,
});

function ProductPage() {
  const [product, setProduct] = useState(null);
  const [selectedImage, setSelectedImage] = useState('');
  const [expandedSection, setExpandedSection] = useState(null);
  const [sizeAndFitText, setSizeAndFitText] = useState('');
  const [sustainabilityText, setSustainabilityText] = useState('');
  const { user } = useAuth();

  useEffect(() => {
    const pathSegments = window.location.pathname.split('/');
    const id = pathSegments.pop();
    if (!id) return;

    const fetchData = async () => {
      try {
        const searchResults = await typesenseClient
          .collections(process.env.REACT_APP_TYPESENSE_COLLECTION_NAME)
          .documents()
          .search({
            q: id,
            query_by: 'ID',
            page: 1,
            per_page: 1,
            exhaustive_search: false,
          });

        const [firstResult] = searchResults.hits;
        setProduct(firstResult.document);
        setSelectedImage(firstResult.document.image_url1 || '');
        console.log('Fetched product:', firstResult.document); // Log the fetched product
        

        // Fetch sizing and sustainability data
        const sizeAndFitResponse = await fetchSizingData(firstResult.document.name);
        const impactResponse = await fetchSustainabilityData(firstResult.document.name);

    
        setSizeAndFitText(sizeAndFitResponse);
        setSustainabilityText(impactResponse);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
 
  const formatPrice = (price) => {
    const priceNumber = typeof price === 'number' ? price : parseFloat(price);
    return `$${Math.floor(priceNumber)}`;
  };


  const onButtonClick = () => {
    // Other things happen when button is clicked
    
    /* Call the Pinterest Lead Event tracker */
    window.pintrk('track', 'lead', {event_id: 'eventId0001'});
  }

  const getAffiliateLink = (url, network, userId, advertiserId) => { 
    console.log('Original URL:', url); 
    console.log('Network:', network); 
    console.log('UserID:', userId); 
    console.log('Advertiser ID:', advertiserId); 
          
    let updatedUrl = url; 
    switch (network) { 
        case 'AWIN': 
            updatedUrl = url.replace(/(awinmid=[^&]+&awinaffid=[^&]+)(.*)/, `$1${userId ? '&clickref=' + userId : ''}$2`); 
            break; 
        case 'CJ': 
            updatedUrl = url.replace(/(click-[^-]+-[^?]+)\?/, `$1${userId ? '?sid=' + userId : ''}&`); 
            break; 
        case 'RAK':
          let murl = new URLSearchParams(url.split('?')[1] || '').get('murl');
          if (!murl) {
              console.error('Unable to extract murl from the given url', url);
              break;
          }
          updatedUrl = `https://click.linksynergy.com/deeplink?id=ZXkZxh*1HDA&mid=${advertiserId}&murl=${encodeURIComponent(murl)}`;
          if (userId) {
              updatedUrl += `&u1=${userId}`;
          }
              break; 
        default: 
            // Leave the url as it is 
            break; 
    } 
    return updatedUrl; 
};


  const fetchSizingData = async (productName) => {
    try {
      const response = await fetch(`https://bard.runwayrewards.shop/sizing?user_input=${encodeURIComponent(productName)}`);
      const data = await response.json();
      return data.response || '';
    } catch (error) {
      console.error('Error fetching sizing data:', error);
      return '';
    }
  };

  const fetchSustainabilityData = async (productName) => {
    try {
      const response = await fetch(`https://bard.runwayrewards.shop/impact?user_input=${encodeURIComponent(productName)}`);
      const data = await response.json();
      return data.response || '';
    } catch (error) {
      console.error('Error fetching sustainability data:', error);
      return '';
    }
  };

  // Add helmet meta tags here, just before your main return statement.
  // Helmet tags will be rendered only when the 'product' state is available.
  let helmetTags = null;
  if (product) {
    const images = Array.from(Array(6).keys()) // creates an array [0,1,2,3,4,5]
    .map(i => product[`image_url${i + 1}`]) // creates an array with your images
    .filter(image => image); // removes any undefined or null images from the array
    helmetTags = (
        <Helmet>
          <title>{`${product.name} - Runway Rewards`}</title>
          <meta name='robots' content="index, nofollow" />
          <meta name='description' content={product.description} />
          <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
          <meta property='og:title' content={`${product.brand} ${product.name}`} />
          <meta property='og:description' content={product.description} />
          <meta property='pinterest:description' content={product.description} />
          <meta property='og:image' content={product.image_url1} />
          <meta property="og:type" content="website" />
          <meta property="og:offers" content={`Price: ${formatPrice(product.price_usd)}`} />
          <meta property="og:site_name" content="Runway Rewards Shop" />
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org/",
              "@type": "Product",
              "name": product.name,
              "image": images,
              "description": product.description,
              "additionalProperty": {
                "@type": "PropertyValue",
                "name": "Size",
                "value": product.size // Make sure to provide the actual size here. I'm assuming product.size exists.
              },
              "offers": {
                "@type": "Offer", 
                "price": product.price_usd,
                "priceCurrency": "USD" // Make sure you adapt if you plan on supporting different currencies
              },
              // Include your review and aggregateRating properties here, adjusted to fit your needs
              "category": product.category, // this could be "category": "Product Subcategory: subcategory, Product Category: category"
            })}
          </script>
        </Helmet>
      );
  }

  return (
    <div className="product-page">
      {helmetTags} {/* add your Helmet meta tags here */}
      <div className="thumbnail-section">
        {[1, 2, 3, 4, 5, 6].map((idx) => {
          const img = product ? product[`image_url${idx}`] : null;
          return img ? (
            <img
              key={idx}
              src={img}
              alt={`${product.brand} ${product.name}`}
              data-pin-description={`${product.description}`}
              onClick={() => setSelectedImage(img)}
              className={img === selectedImage ? 'active-thumbnail' : ''}
            />
          ) : null;
        })}
      </div>

      <div className="image-section">
      <img src={selectedImage} alt={product ? `${product.brand} ${product.name}` : ''} />
      
      </div>

      {product && (
        <div className="details-section">
          <div className="popup-header">
            <span>{product.brand}</span>
            <span>{product.category}</span>
          </div>
          <div className="product-name">{product.name}</div>
          <div className="medium-text">{formatPrice(product.price_usd)}</div>
          <div className="medium-text">{product.cashback}</div>

          {/* Retool component with updated data */}
          <div className="expandable-section">
            <div
              className="section-header"
              onClick={() => setExpandedSection(expandedSection === 'Personalized Sizing Tool' ? null : 'Personalized Sizing Tool')}
            >
              <div className="section-title">Personalized Sizing Tool</div>
              <div className="expand-icon">{expandedSection === 'Personalized Sizing Tool' ? '-' : '+'}</div>
            </div>
            {expandedSection === 'Personalized Sizing Tool' && (
              <div className="section-content">
                <Retool
                  url="https://runwayrewards.retool.com/embedded/public/9f1bbb39-c1dc-4abb-a3f8-011d7b2be023"
                  data={{ productName: product.name, productSize: product.size }}
                />
              </div>
            )}
          </div>

          {/* Expandable Sections */}
          {['Size and Fit', 'Description and Style Recommendations', 'Materials, Impact, and Sustainability Information'].map((section) => (
            <div key={section} className="expandable-section">
              <div
                className="section-header"
                onClick={() => setExpandedSection(section === expandedSection ? null : section)}
              >
                <div className="section-title">{section}</div>
                <div className="expand-icon">{section === expandedSection ? '-' : '+'}</div>
              </div>
              {section === expandedSection && (
                <div className="section-content">
                  {section === 'Description and Style Recommendations' ? product.description : ''}
                  {section === 'Materials, Impact, and Sustainability Information' && (
                    <SustainabilityDetails sustainabilityText={sustainabilityText} />
                  )}
                  {section === 'Size and Fit' && <SizeAndFitDetails sizeAndFitText={sizeAndFitText} />}
                </div>
              )}
            </div>
          ))}
 {(() => {
                    // Calculate affiliateLink inside the map to ensure product is no longer null.
                    const affiliateLink = getAffiliateLink(
                        product.link,
                        product.network,
                        user?.uid,
                        product.advertiserId
                    );
                    return (
                        <a
                            href={affiliateLink}
                            className="secondary-button"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={onButtonClick}
                        >
                            {user ? 'Buy with CashBack' : 'Buy Now from Store'}
                        </a>
                    );
                })()}
        </div>
      )}
    </div>
  );
}

export default ProductPage;
