import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './LandingPage.module.css';
//import image1 from '../images/landingalpha1.png';
import image1 from '../images/bags/1.svg';
import image1Mobile from '../images/bags/1.svg';
import image2 from '../images/bags/2.svg';
import image2Mobile from '../images/bags/2.svg';
import image3 from '../images/bags/3.svg';
import image3Mobile from '../images/bags/3.svg';

const LandingPage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const history = useHistory();

  const handleSearch = () => {
    if (searchQuery.trim() !== '') {
      history.push(`/search?q=${encodeURIComponent(searchQuery)}`);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles['image-1-wrapper']}>
        <picture>
          <source media="(max-width: 768px)" srcSet={image1Mobile} />
          <source media="(min-width: 769px)" srcSet={image1} />
          <img src={image1} className={styles.image} alt="First image" />
        </picture>
        <div className={styles['search-bar']}>
          <input
            type="text"
            placeholder="Red Birkin 30 Platinum Hardware"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <button onClick={handleSearch}>🔍</button>
        </div>
      </div>
      <picture>
        <source media="(max-width: 768px)" srcSet={image2Mobile} />
        <source media="(min-width: 769px)" srcSet={image2} />
        <img src={image2} className={styles.image} alt="Second image" />
      </picture>
      <picture>
        <source media="(max-width: 768px)" srcSet={image3Mobile} />
        <source media="(min-width: 769px)" srcSet={image3} />
        <img src={image3} className={styles.image} alt="Third image" />
      </picture>
    </div>
  );
};

export default LandingPage;