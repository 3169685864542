// Import the functions you need from the SDKs you need
// firebase.js

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDzW9YJxzCui_2VDcXsUm8ZgYnyZnImQu4",
  authDomain: "runway-rewards.firebaseapp.com",
  projectId: "runway-rewards",
  storageBucket: "runway-rewards.appspot.com",
  messagingSenderId: "421065321288",
  appId: "1:421065321288:web:4092bb5616c90bf01e217d",
  measurementId: "G-62GN7053MN"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);  // Exporting db for Firestore access
