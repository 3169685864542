import React, { useState, useEffect, useRef } from 'react';
import { InstantSearch, Configure, connectSearchBox, connectRefinementList, connectRange, connectInfiniteHits, Panel } from 'react-instantsearch-dom';
import { FaHeart, FaSearch } from 'react-icons/fa';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import ProductPopup from './ProductPopup';
import './TypeSenseSearch.css';
import { getAuth } from 'firebase/auth';
import { updateDoc, arrayUnion, arrayRemove, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';


function useOutsideAlerter(ref, onOutsideClick) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onOutsideClick();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [ref, onOutsideClick]);
}


const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: process.env.REACT_APP_TYPESENSE_API_KEY_2,
    nodes: [{
      host: process.env.REACT_APP_TYPESENSE_HOST,
      port: parseInt(process.env.REACT_APP_TYPESENSE_PORT, 10),
      protocol: process.env.REACT_APP_TYPESENSE_PROTOCOL,
    }],
    cacheSearchResultsForSeconds: 2 * 60,
  },
  additionalSearchParameters: {
    query_by: 'productName,Brand,Gender,Store,Color,sub_category'
  },
});

const searchClient = typesenseInstantsearchAdapter.searchClient;

const Hit = ({ hit }) => {
  const [wishlist, setWishlist] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const auth = getAuth();
  const user = auth.currentUser;
  const firestore = db;

  console.log("Hit data:", hit);

  useEffect(() => {
    if (user) {
      const fetchWishlist = async () => {
        const userRef = doc(firestore, 'Users', user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists() && Array.isArray(userDoc.data().wishlist)) {
          setWishlist(userDoc.data().wishlist);
        }
      };
      fetchWishlist();
    }
  }, [user, firestore]);

  const toggleWishlist = async (event, hitId) => {
    console.log("Received hitId in toggleWishlist:", hitId);
    event.stopPropagation();
    console.log("Before conversion - hitId:", hitId, "Type:", typeof hitId); // Log before conversion
    // Check if the user is not null
    if (!user) {
      console.log("User is not logged in");
      return; // or handle the scenario when the user is not logged in
    }
    const stringHitId = hitId.toString(); // Convert hitId to a string
    console.log("After conversion - hitId:", stringHitId, "Type:", typeof stringHitId); // Log after conversion
    const userRef = doc(firestore, 'Users', user.uid);
    if (wishlist.includes(stringHitId)) {
      await updateDoc(userRef, {
        wishlist: arrayRemove(stringHitId)
      });
      setWishlist(wishlist.filter(id => id !== stringHitId));
    } else {
      await updateDoc(userRef, {
        wishlist: arrayUnion(stringHitId)
      });
      setWishlist([...wishlist, stringHitId]);
    }
  };
  

  const handleProductClick = () => {
    setShowPopup(true);
  };

  return (
    <div className="ts-card">
      <div 
        className="ts-wishlist-icon"
        onClick={(event) => toggleWishlist(event, hit.ID)}>
        <FaHeart className={wishlist.includes(hit.ID) ? 'ts-iconOnWishlist' : ''} />
      </div>
      
      <div 
        onClick={handleProductClick}
        className="ts-image-container">
        <img 
          src={hit._id}
          alt={hit.productName} 
          className="ts-image" 
        />
      </div>

      <div 
        onClick={handleProductClick} 
        className="ts-content-container">
        <div className="ts-brand">{hit.Brand}</div>
        <div className="ts-name">{hit.productName}</div>
        

        <div className="ts-price">
          ${hit.Price}
        </div>
      </div>

      {showPopup && (
        <ProductPopup 
          product={hit}
          onClose={() => setShowPopup(false)} 
        />
      )}

    </div>
  );
};

const CustomSearchBox = ({ currentRefinement, refine }) => {
  return (
    <div className="searchBarContainer">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          refine(currentRefinement);
        }}
        className="searchBar"
      >
        <div className="inputContainer">
          <input
            type="text"
            placeholder="Search"
            value={currentRefinement}
            onChange={(e) => refine(e.target.value)}
            className="input"
          />
          <button type="submit" className="searchIcon">
            <FaSearch color="black" />
          </button>
        </div>
      </form>
    </div>
  );
};

const ConnectedSearchBox = connectSearchBox(CustomSearchBox);

const useOutsideClick = (ref, callback) => {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      console.log("Outside click detected");
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

const CustomPicker = connectRefinementList(({ items, refine, attribute }) => {
  const [showModal, setShowModal] = useState(false);
  const modalRef = useRef(null);
  useOutsideClick(modalRef, () => setShowModal(false));

  return (
    <div className="pickerContainer">
      <div onClick={() => setShowModal(true)} className="filterLabel">{attribute}</div>
      {showModal && (
        <div className="modalOverlay">
          <div className="modalContent" ref={modalRef}>
            {items.map(item => (
              <div key={item.label} className="filterOption">
                <input
                  type="checkbox"
                  id={`option-${item.label}`}
                  checked={item.isRefined}
                  onChange={() => refine(item.value)}
                />
                <label htmlFor={`option-${item.label}`}>{item.label}</label>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
});



const CustomRangeSlider = connectRange(({ min, max, currentRefinement, refine }) => {
  const [minLabelStyle, setMinLabelStyle] = useState({});
  const [maxLabelStyle, setMaxLabelStyle] = useState({});

  const updateLabelPosition = (value, isMin) => {
    const percent = ((value - min) / (max - min)) * 100;
    return {
      left: `calc(${percent}% + (${12 - percent * 0.24}px))`,
    };
  };

  useEffect(() => {
    setMinLabelStyle(updateLabelPosition(currentRefinement.min, true));
    setMaxLabelStyle(updateLabelPosition(currentRefinement.max, false));
  }, [currentRefinement, min, max]);

  const onSliderChange = (event) => {
    const value = parseFloat(event.target.value);
    const isMinHandle = event.target.className.includes('min');
    if (isMinHandle) {
      refine({ min: value, max: currentRefinement.max });
    } else {
      refine({ min: currentRefinement.min, max: value });
    }
  };

  return (
    <div className="rangeSliderContainer">
      <input
        type="range"
        min={min}
        max={max}
        value={currentRefinement.min}
        onChange={onSliderChange}
        className="rangeSlider blackSlider min"
      />
      <input
        type="range"
        min={min}
        max={max}
        value={currentRefinement.max}
        onChange={onSliderChange}
        className="rangeSlider blackSlider max"
      />
      <div className="priceLabel" style={minLabelStyle}>${currentRefinement.min}</div>
      <div className="priceLabel" style={maxLabelStyle}>${currentRefinement.max}</div>
    </div>
  );
});

const InfiniteProductHits = connectInfiniteHits(({ hits, hasMore, refineNext }) => {
  useEffect(() => {
    const onScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop > document.documentElement.offsetHeight - 500 && hasMore) {
        refineNext();
      }
    };

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [hasMore, refineNext]);

  return (
    <div className="hits">
      {hits.map(hit => <Hit key={hit.objectID} hit={hit} />)}
    </div>
  );
});

const TypeSenseSearch = () => {
  // State to hold the search query
  const [searchQuery, setSearchQuery] = useState('');

  // Read the query parameter from the URL
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const query = params.get('q') || '';
    setSearchQuery(query);
  }, []);

  // Optionally, update the URL when the search query changes
  useEffect(() => {
    const url = new URL(window.location);
    url.searchParams.set('q', searchQuery);
    window.history.pushState({}, '', url);
  }, [searchQuery]);

  return (
    <InstantSearch 
      indexName={process.env.REACT_APP_TYPESENSE_COLLECTION_NAME_2} 
      searchClient={searchClient}
      searchState={{ query: searchQuery }}
      onSearchStateChange={({ query }) => setSearchQuery(query)}
    >
      <Configure hitsPerPage={30} />
      <ConnectedSearchBox />
      
      <div className="ts-grid-container">
        <InfiniteProductHits />
      </div>
    </InstantSearch>
  );
};

export default TypeSenseSearch;

