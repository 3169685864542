import React from 'react';

function SustainabilityDetails({ sustainabilityText }) {
 const renderParagraphs = () => {
   // Split the text into paragraphs using line breaks
   const paragraphs = sustainabilityText.split('\n');

   return paragraphs.map((paragraph, index) => (
     <div key={index} className="sustainability-paragraph">
       {/* Parse bold text and apply styles */}
       {paragraph.split('**').map((text, i) =>
         i % 2 === 0 ? (
           // Normal text
           <span key={i}>{text}</span>
         ) : (
           // Bold text
           <strong key={i}>{text}</strong>
         )
       )}
     </div>
   ));
 };

 return (
   <div className="sustainability-details">
     {renderParagraphs()}
   </div>
 );
}

export default SustainabilityDetails;